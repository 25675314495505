:root {
    color-scheme: dark;
}

body {
    font-family: 'Ubuntu Sans', Arial, sans-serif;
}

h1, h2, h3, h4, h5 {
    font-family: 'Lato';
    font-weight: 300;
}

.container {
    max-width: 1100px;
}

.main-page {
    background: repeating-linear-gradient(-55deg,
        var(--bs-body-bg),
        var(--bs-body-bg) 1000px,
        #34383d 1000px,
        #34383d 2000px);
}

/* Disable AOS on mobile screen */
@media only screen and (max-width: 480px) {
    [data-aos] {
        opacity: 1 !important;
        transform: translate(0) scale(1) !important;
    }
}